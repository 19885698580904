<template>
  <b-card class="prozess-digital-signature">
    <div>
      <h2> {{ $t('Digital Signature') }} </h2>
      <p class="mb-4" v-if="hasReadOnlyPermission">{{ $t('You only have read-only permission to this page') }}</p>
      <div v-if="loading" class="d-flex justify-content-center">
        <b-spinner />
      </div>
      <div>

      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  data() {
    return {
      showMessage: false,
    }
  },
  created() {
    this.getInitialData()
  },
  methods: {
    async getInitialData() {
      try {
      console.log('getInitialData')
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@/assets/scss/master-variables.scss';
</style>
